import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.starts-with.js";
import "core-js/modules/es.array.push.js";
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-e379f866"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = ["data-ga", "onClick"];
var _hoisted_2 = {
  class: "text-sm"
};
import { ref, watchEffect, computed, useAttrs } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { DOCS_URL } from '@/constants/config';
import { loadToken } from '@/utils/common';
import toast from '@/utils/toast';
import { useI18n } from 'vue-i18n';
export default {
  __name: 'navList',
  props: {
    type: {
      type: String,
      default: 'line'
    }
  },
  setup: function setup(__props) {
    var router = useRouter();
    var route = useRoute();
    var store = useStore();
    var _useI18n = useI18n({
        useScope: 'global'
      }),
      t = _useI18n.t;
    var _useAttrs = useAttrs(),
      onClick = _useAttrs.onClick;

    // 当前菜单
    var activeNav = ref('QrCodePayment');

    // 菜单列表
    var navList = computed(function () {
      var info = store.state._info.setupInfo;
      return [{
        text: 'route.contractPayment',
        val: 'ContractPayment',
        path: info.contractSetUp ? '/' : '/contract-payment/step/' + info.contractStep,
        icon: 'bm bm-contract'
      }, {
        text: 'route.qrCodePayment',
        val: 'QrCodePayment',
        path: info.qrCodeSetUp ? '/qrcode-payment' : '/qrcode-payment/step/' + info.qrCodeStep,
        icon: 'bm bm-contract'
      }, {
        text: 'route.docs',
        path: DOCS_URL,
        val: 'Docs',
        icon: 'bm bm-catalog'
      }];
    });

    // 识别菜单的当前效果
    watchEffect(function () {
      var routeName = route.name === 'Login' ? 'QrCodePayment' : route.name || '';
      var findName = navList.value.find(function (el) {
        return routeName.indexOf(el.val) !== -1;
      });
      if (findName) {
        activeNav.value = findName.val;
      }
    });

    // 点击菜单
    var launchNav = function launchNav(item) {
      var connected = store.state._info.connectInfo.connected;
      var contractFlag = store.state._info.merchantInfo.contractFlag;
      var token = loadToken();
      if (item.val === 'ContractPayment' && token && !contractFlag) {
        return toast.alert(t('login.contractRoleTips'));
      }
      if (item.path.startsWith('http')) {
        window.open(item.path);
      } else if (!token && !connected && item.val === 'QrCodePayment') {
        router.push({
          name: 'Login'
        });
      } else {
        router.push(item.path);
      }
      onClick && onClick();
    };
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("nav", {
        class: _normalizeClass(__props.type + '-nav')
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(navList), function (item, index) {
        return _openBlock(), _createElementBlock("a", {
          key: index,
          class: _normalizeClass(['nav-item', {
            active: activeNav.value === item.val
          }]),
          "data-ga": "menu,".concat(item.path),
          onClick: function onClick($event) {
            return launchNav(item);
          }
        }, [_createElementVNode("i", {
          class: _normalizeClass(["mr-3 text-xl", [item.icon]])
        }, null, 2), _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t(item.text)), 1)], 10, _hoisted_1);
      }), 128))], 2);
    };
  }
};